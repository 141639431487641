a[target='_blank']:after {
  content: '\1F5D7'
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }

/* 
  Style for default content block
*/
.contentTitle {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.defaultContainer {
   background-color:#F6F7FB;
   padding-right: 0;
   padding-left: 0;
   max-width: 100%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
