.PhRegistration .MuiPaper-rounded {
    border-radius: 12px;
}

.PhRegistration .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* padding-left: unset;
    padding-right: unset; */
}


.PhRegistration .IDS-Autocomplete-root {
    margin-top: 16px;
    margin-bottom: 8px;
}

/* .PhRegistration .IDS-Autocomplete-root > .MuiFormControl-root {
    margin-top: 0px;
} */




.PhRegistration .MuiGrid-item {
    padding-top: unset;
    /* padding-left: unset;
    padding-right: unset; */
}






/* MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-pzcl1u-MuiPaper-root-MuiCard-root */