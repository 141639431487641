.api-error-banner {
    /* Style the banner container here */
    background-color: #ff3333;
    padding: 10px;
    border: 1px solid #ddd;
    position: fixed;
    top: 10px;
    left: 30%;
    right: 30%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 999; 
}

.api-error-banner.visible {
    opacity: 1;
}