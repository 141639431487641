.ssomodal li {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}
.ssomodal ul {
    padding-left: 24px;
}

.ssomodal li > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
}

@media (max-width: 600px) {
    .ssomodal img {
        width: 100% !important;
        height: auto;
        max-width: 100% !important;
    }
  }

