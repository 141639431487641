.PhFooter a[target='_blank']:after {
    display: none;
  }

  .PhFooter a:has(svg) {
    height: unset;
  }

  .PhFooter {
    background-color: unset;
  }

.PhFooter .MuiTypography-root {
    align-self: center;
    padding-top: 15px;
    padding-left: 0;
}

.MuiGrid-item:has(.PhFooter) {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    z-index: 1000;
}

@media (min-width: 600px) and (max-width: 900px) {

    .PhFooter > div > div {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 15px;
    }

    .PhFooter > div > div > div {
        flex-direction: row;
    }

    .PhFooter .MuiTypography-root {
        align-self: flex-end;
    }
  }

  @media (max-width: 600px) {
    .PhFooter .MuiTypography-root {
        align-self: flex-end;
        padding-top: 0;
    }

    .PhFooter > div {
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
    }
  }
