#root:has(.LandingPage) {
    background: url(./wavesBody.svg),linear-gradient(to bottom, rgba(0, 191, 179, 0.6) 8%, rgba(0, 191, 179, 0.16) 100%),#005587;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 100%, cover;
    min-height: 100vh;
}

#root:has(.LandingPage) .container {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
}

/* .MuiGrid-item:has(.MuiBottomNavigation-root) {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}

div:has(header) {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
} */

